"use client";

import Image from "next/image";
import Link from "next/link";
import { useEffect } from "react";
import Desktop404Image from "@/assets/images/desktop404.png";
import Mobile404Image from "@/assets/images/mobile404.png";
import { ArrowRight } from "lucide-react";
import { trackNotFound } from "@/utils/googleTag";

const NotFoundPage = () => {
  useEffect(() => {
    trackNotFound();
  }, []);

  return (
    <div className="py-12 lg:py-20 px-5 max-w-6xl mx-auto flex flex-col-reverse lg:flex-row justify-center items-center">
      <section className="flex flex-col w-full lg:w-[45%]">
        <h1 className="font-semibold text-[32px] lg:text-[48px] text-[#141414]">Page Not Found</h1>
        <p className="text-[16px] text-gray-500 mb-8 mt-2">
          Sorry, the page you are looking for doesn't
          <br className="lg:hidden" /> exist.
        </p>
        <Link
          href="/"
          className="blue-button text-[16px] leading-[25px] w-full lg:w-fit pr-4 pl-5 lg:py-4 lg:rounded-[24px]"
        >
          To Home Page <ArrowRight className="w-6 h-6 ml-2" />
        </Link>
      </section>
      <section className="w-[55%] !hidden lg:!block">
        <Image src={Desktop404Image} />
      </section>
      <section className="w-[90%] lg:!hidden !block pl-8">
        <Image src={Mobile404Image} />
      </section>
    </div>
  );
};

export default NotFoundPage;
