"use client";

import { navigation } from "@/constants/header";
import SideMenuCollapsableSection from "./SideMenuCollapsableSection";
import { Popover, PopoverButton, PopoverPanel, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import { MenuIcon } from "@heroicons/react/outline";
import { LogOutIcon, X } from "lucide-react";
import { socials } from "@/constants/footer";
import AmericanFlagIcon from "@/assets/images/AmericanFlagIcon.png";
import useLogout from "@/hooks/useLogout";
import { useSession } from "next-auth/react";
import Image from "next/image";

export const SideMenu = () => {
  const [expanded, setExpanded] = useState();
  const logout = useLogout();
  const { status } = useSession();
  const isLogged = status === "authenticated";

  return (
    <Popover>
      {({ open, close }) => (
        <>
          <PopoverButton
            name="Toggle navigation"
            className="flex lg:hidden focus:outline-none text-gray-800 hover:text-gray-500"
          >
            <span className={`text-lg`}>
              <span className="sr-only">Toggle navigation</span>
              {!open && <MenuIcon className="cursor-pointer w-5 h-5 m-[0px_2px]" />}
              {open && <X className="cursor-pointer w-6 h-6" />}
            </span>
          </PopoverButton>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <PopoverPanel className="absolute z-[900] w-[100vw] top-[90px] left-0 rounded-[0_0_30px_30px] drop-shadow-[0_4px_2px_#0003] border-t border-gray-200 bg-white flex flex-col py-6">
              <div className="grid gap-4">
                <SideMenuCollapsableSection
                  title="Shop"
                  expanded={expanded === "shop"}
                  setExpanded={(e) => setExpanded(e ? "shop" : "")}
                  items={navigation.shop}
                  onAfterClick={close}
                />
                <SideMenuCollapsableSection
                  title="Learn"
                  expanded={expanded === "learn"}
                  setExpanded={(e) => setExpanded(e ? "learn" : "")}
                  items={navigation.learn}
                  onAfterClick={close}
                />
                <SideMenuCollapsableSection
                  title="Support"
                  expanded={expanded === "support"}
                  setExpanded={(e) => setExpanded(e ? "support" : "")}
                  items={navigation.support}
                  onAfterClick={close}
                />
                <SideMenuCollapsableSection
                  title="Company"
                  expanded={expanded === "company"}
                  setExpanded={(e) => setExpanded(e ? "company" : "")}
                  items={navigation.company}
                  onAfterClick={close}
                />
              </div>

              {isLogged && (
                <section className="mt-6 w-full flex justify-center items-center">
                  <button
                    type="button"
                    onClick={() => {
                      logout();
                    }}
                    className="cursor-pointer flex justify-center items-center space-x-2 text-gray-800"
                  >
                    <LogOutIcon className="text-gray-500 h-5 w-5" />
                    <span>Log Out</span>
                  </button>
                </section>
              )}
              <section className="px-4 mt-6">
                <div className="flex flex-row mb-5 text-gray-500 text-sm">
                  <Image
                    src={AmericanFlagIcon}
                    width={24}
                    height={17}
                    alt="made in USA"
                    className="!w-[24px] !h-[17px] object-fit !min-h-0"
                  />

                  <p className="ml-3 mt-1">USD currency</p>
                </div>
                <ul className="flex space-x-4 -mb-2">
                  {socials.map(({ Icon, ...social }) => (
                    <li
                      key={social.id}
                      id={social.id}
                      className="hover:-translate-y-0.5 transition ease-in duration-200"
                    >
                      <a href={social.href} target="_blank">
                        <Icon alt={social.label} height={24} width={24} />
                      </a>
                    </li>
                  ))}
                </ul>
              </section>
            </PopoverPanel>
          </Transition>
        </>
      )}
    </Popover>
  );
};
