import { Popover, PopoverButton, PopoverPanel, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import classNames from "classnames";
import Image from "next/image";
import Link from "next/link";

export const PopoverNavigation = ({ title = "", items = [], className = "", children }) => {
  return (
    // eslint-disable-next-line react/no-children-prop
    <Popover className={classNames("relative", className)}>
      {({ open, close }) => {
        return (
          <>
            <PopoverButton
              className="flex focus:outline-none text-gray-800 hover:text-gray-500 mt-0"
              onBlur={close}
            >
              <span className={`text-[20px] font-semibold ${open ? "text-blue-500" : ""}`}>
                {title}
              </span>{" "}
              <ChevronDownIcon
                className={`h-[22px] w-[22px] ml-[3px] mt-[4.45px] scale-y-[1.07] ${
                  open ? "rotate-180 text-blue-500" : "text-[#444]"
                }`}
              />
            </PopoverButton>
            <Transition
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <PopoverPanel className="absolute z-10 top-10 bg-white drop-shadow-[0_2px_5px_#0003] rounded-[20px] pt-2.5 pb-5 flex flex-col min-w-[320px]">
                {items.map(({ Icon, ...item }) => (
                  <Link
                    key={item.name}
                    href={item.href}
                    {...(!item.prefetch && { prefetch: false })}
                    className="cursor-pointer text-lg text-black hover:text-blue-500 rounded-[12px] transition-all duration-200 px-5 py-2.5 flex items-center justify-left space-x-4 focus:outline-none"
                  >
                    {item?.image && (
                      <Image src={item.image} width={70} height={70} alt={item.name} />
                    )}
                    {Icon && (
                      <div className="bg-[#E9EFFD] rounded-lg flex items-center justify-center w-[62px] h-[62px]">
                        <Icon width={32} height={32} />
                      </div>
                    )}
                    <span>{item.name}</span>
                  </Link>
                ))}
                {children}
              </PopoverPanel>
            </Transition>
          </>
        );
      }}
    </Popover>
  );
};
