"use client";

import Link from "next/link";
import { useState } from "react";
import ProfileIcon from "@/assets/svg/ProfileIcon.svg";
import { usePathname, useRouter } from "next/navigation";
import HeadlessModal from "../HeadlessModal";
import { useSession } from "next-auth/react";

export const LoggedButtons = () => {
  const router = useRouter();
  const pathname = usePathname();
  const { status } = useSession();
  const isLogged = status === "authenticated";
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const onLoginClick = () => {
    if (["/create/[orderId]/[designId]/lasso"].includes(pathname))
      setShowConfirmationModal("/auth/signin");
    else router.push("/auth/signin");
  };

  return (
    <>
      {!isLogged && (
        <button
          onClick={onLoginClick}
          className="cursor-pointer inline-flex items-center border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-gray-400 hover:text-gray-700 focus:outline-none "
        >
          <ProfileIcon
            height={24}
            width={24}
            className="lg:h-6 lg:w-6 h-[25px] w-[25px] -mr-2 text-[#8f8f8f] hover:text-gray-700 cursor-pointer"
          />
        </button>
      )}
      {isLogged && (
        <Link href="/profile" prefetch={false} aria-label="Profile">
          <ProfileIcon
            height={24}
            width={24}
            className="lg:h-6 lg:w-6 h-[25px] w-[25px] -mr-1.5 text-[#8f8f8f] hover:text-gray-700 cursor-pointer"
          />
        </Link>
      )}
      <HeadlessModal
        isOpen={showConfirmationModal}
        onClose={() => setShowConfirmationModal(false)}
        variant="danger"
        title="Are you sure?"
        text="You can sign in after creating your custom freshener! If you leave the page now, you might need to restart the process."
        onCancel={() => setShowConfirmationModal(false)}
        onConfirm={() => {
          router.push(showConfirmationModal);
          setShowConfirmationModal(false);
        }}
      />
    </>
  );
};
