export const changeNumber = (qnty) => {
  const cartItems = document.getElementById("cart-items");

  if (!cartItems) return;

  if (!qnty) {
    cartItems.style.display = "none";
    return;
  }

  cartItems.firstChild.replaceWith(qnty > 99 ? "+99" : qnty);
  cartItems.style.display = "flex";
};

const updateHeaderCartQnty = (order) => {
  if (order?.items?.length) {
    changeNumber(order?.items?.reduce((acc, item) => item?.quantity + acc, 0));
    return;
  }

  const reduxPersist = JSON.parse(localStorage.getItem("persist:root"));
  if (reduxPersist?.Order) {
    const OrderId = JSON.parse(reduxPersist?.Order)?.items?.[0];
    const items = JSON.parse(reduxPersist?.Order)?.itemsById?.[OrderId]?.items;

    changeNumber(items?.reduce((acc, item) => item?.quantity + acc, 0));
  } else changeNumber(0);
};

export default updateHeaderCartQnty;
