"use client";

import { useCallback, useState } from "react";
import LoadingSpinner from "../LoadingSpinner";
import { ArrowRight } from "lucide-react";
import { toast } from "react-toastify";
import { newsletterSubscribe } from "@/services/CustomerService";

export const EmailInput = () => {
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleNewsletterSubscription = useCallback(() => {
    setIsLoading(true);
    newsletterSubscribe(email)
      .then(() => {
        toast.info("Welcome! You've successfully joined our newsletter.");
        setEmail("");
      })
      .catch((e) => {
        toast.error(e?.message || "Sorry! Something went wrong.");
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [email]);

  return (
    <>
      <input
        className="default-input w-full sm:max-w-[30%] rounded-full px-4 py-2 text-lg"
        placeholder="Enter your email"
        type="email"
        readOnly={isLoading}
        onChange={(e) => setEmail(e.target.value)}
      />
      <button
        className="white-button rounded-[16px] w-full sm:w-fit sm:text-base text-xl"
        onClick={handleNewsletterSubscription}
        disabled={isLoading || !email}
        type="button"
      >
        {isLoading && <LoadingSpinner className="text-gray-400" />}
        Submit <ArrowRight className="h-6 w-6 ml-3" />
      </button>
    </>
  );
};
