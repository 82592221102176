"use client";

import { PopoverNavigation } from "./PopoverNavigation";
import { navigation } from "@/constants/header";

export const HeaderMenus = () => {
  return (
    <>
      <PopoverNavigation title="Shop" items={navigation.shop} />
      <PopoverNavigation title="Learn" items={navigation.learn} />
      <PopoverNavigation title="Support" items={navigation.support} />
      <PopoverNavigation title="Company" items={navigation.company} />
    </>
  );
};
