let Sentry;
let queue = [];
let loading = false;
const dsn = process.env.NEXT_PUBLIC_SENTRY_DSN;

export async function startSpan(params, cb) {
  if (Sentry) {
    return Sentry.startSpan(params, cb);
  }
  return cb();
}

export async function captureMessage(message) {
  if (Sentry) {
    Sentry.captureMessage(JSON.stringify(message));
  }
}

export function initSentry() {
  if (loading) return;

  const isDevelopment = process.env.NODE_ENV === "development";
  if (!Sentry && dsn && !isDevelopment) {
    const isStaging = process.env.NEXT_PUBLIC_API_BASE_URL.includes("staging");
    loading = true;
    import("@sentry/react")
      .then((sentryObj) => {
        Sentry = sentryObj;
        Sentry.init({
          dsn,
          environment: isStaging ? "staging" : "production",
          integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
          // Performance Monitoring
          tracesSampleRate: 1.0, //  Capture 100% of the transactions
          // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
          tracePropagationTargets: ["localhost", /makemyfreshener\.com\/api/],
          // Session Replay
          replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
          replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        });
        if (queue.length) {
          for (let i = 0; i < queue.length; i++) {
            const error = queue[i];
            Sentry.captureException(error);
          }
          queue = [];
        }
      })
      .finally(() => {
        loading = false;
      });
  }
}

export function logError(exception) {
  if (!Sentry) {
    if (dsn) {
      queue.push(exception);
      initSentry();
    }
  } else {
    Sentry.captureException(exception);
  }
}
