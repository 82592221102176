"use client";

import { getRecoverOrder } from "@/services/OrderService";
import { saveUTMParams } from "@/utils/storage";
import updateHeaderCartQnty from "@/utils/updateHeaderCartQnty";
import { usePathname } from "next/navigation";
import { useEffect } from "react";

export const ClientUtils = () => {
  const pathname = usePathname();
  const isDesignPage = pathname.includes("/design");
  const isCustomShapeFlow =
    pathname.includes("lasso") || pathname.includes("approve") || pathname.includes("crop");
  const hideHeader = isDesignPage || isCustomShapeFlow;
  const isCreationFlow =
    (pathname.includes("shop") && pathname !== "/shop") || pathname.includes("create");
  const hideFooter = isCreationFlow;

  useEffect(() => {
    // Scroll to top on route change
    window.scrollTo(0, 0);
  }, [pathname]); // Trigger on pathname changes

  useEffect(() => {
    saveUTMParams();
  }, []);

  useEffect(() => {
    getRecoverOrder()
      .then((order) => updateHeaderCartQnty(order))
      .catch(() => undefined);
  }, []);

  return (
    <div className={`${hideFooter ? "hide-footer" : ""} ${hideHeader ? "hide-header" : ""}`}></div>
  );
};
